<template>
  <section id="zoom">
    <div class="ma-10">
      <div class="display-1 info--text font-weight-bold">Zoom API</div>
      <div class="body-2 mt-10 mb-5 ml-1">CREDENTIALS</div>
      <v-sheet outlined class="col-sm-12 col-lg-6">
        <v-card class="mb-3" flat v-if="!edit">
          <v-btn
            color="primary"
            fab
            absolute
            right
            x-small
            @click="edit = !edit"
            style="margin-top: -30px"
          >
            <v-icon size="18">mdi-pencil</v-icon>
          </v-btn>
        </v-card>
        <v-card class="mb-3" flat v-else>
          <v-btn
            color="success"
            fab
            absolute
            right
            x-small
            @click="save"
            style="margin-top: -30px"
            class="mr-10"
          >
            <v-icon size="18">mdi-upload</v-icon>
          </v-btn>
          <v-btn
            color="danger"
            dark
            fab
            absolute
            right
            x-small
            @click="edit = !edit"
            style="margin-top: -30px"
          >
            <v-icon size="18">mdi-close</v-icon>
          </v-btn>
        </v-card>

        <label class="caption">ZOOM KEY *</label>
        <v-text-field solo dense v-if="edit" v-model="user.zoom_key" />
        <div class="body-2 ma-2 #bdc3c7--text" v-else>
          <i> {{ user.zoom_key ? user.zoom_key : "..." }}</i>
        </div>

        <label class="caption">ZOOM SECRET *</label>
        <v-text-field solo dense v-if="edit" v-model="user.zoom_secret" />
        <div class="body-2 ma-2 #bdc3c7--text" v-else>
          <i>{{ user.zoom_secret ? user.zoom_secret : "..." }}</i>
        </div>
      </v-sheet>
    </div>
    <snackbar
      :snack="snack"
      :text="text"
      timeout="3000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    edit: false,
    snack: false,
    text: "",
  }),
  computed: {
    ...mapState("admin", {
      user: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions("admin", ["storeZoomApi", "authAction"]),

    save() {
      this.storeZoomApi(this.user).then(() => {
        if (this.$errors("admin")) return;

        this.authAction().then(() => {
          this.snack = true;
          this.text = "Zoom Api Saved!";
        });
      });
      this.edit = !this.edit;
    },
  },
};
</script>